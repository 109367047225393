import { useEffect, useState } from 'react';
import Subheader from '../Subheader';
import Table from '../Table/Table';
import Body from '../Body';
import Manage from './Manage';
import {
    _getCountries,
    Country,
    _getPosters,
    _deletePoster
} from 'routes/routes';
import { formatPosterTable } from '../TableService';
import { useSnackbar } from 'notistack';
import useError from 'hooks/useError';
import OrderPosters from './OrderPosters';

import { confirmDialog } from 'primereact/confirmdialog';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
const List: React.FC = () => {
    const setError = useError();
    const { enqueueSnackbar } = useSnackbar();
    const [updateId, setUpdateId] = useState<number | null>(null);
    const [data, setData] = useState<any[]>([]);
    const [newData, setNewData] = useState<any[]>([]);
    const [redrawTable, setRedrawTable] = useState<Boolean>(false);
    const [isOpenManage, setIsOpenManage] = useState(false);
    const [action, setAction] = useState<string>('New');
    const [selectedCountry, setselectedCountry] = useState<string>('-1');
    const [countries, setCountries] = useState<Country[]>([]);
    const [tab, setTab] = useState('1');
    const [resetFilters, setResetFilters] = useState<boolean>(false);
    const [selectedOrderCountry, setSelectedOrderCountry] =
        useState<string>('-1');
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await _getPosters();
                const countries = await _getCountries();
                setCountries(countries.data);
                setData(response.data);
                setNewData(response.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchOptions();
    }, [isOpenManage, redrawTable]);
    const { formattedColumns, formattedRows } = formatPosterTable(newData);
    const handleEdit = (id: number) => {
        // Logic to handle editing a row based on the provided ID
        // enqueueSnackbar('Item edited ', { variant: 'success' });
        setUpdateId(id);
        setAction('Edit');
        setIsOpenManage(true);
    };
    const handleOpenmanage = () => {
        setAction('New');
        setIsOpenManage(true);
    };
    const handleCloseManage = () => {
        setUpdateId(null);
        setIsOpenManage(false);
        setselectedCountry('-1');
    };

    // Function to handle deleting a row
    const handleDelete = async (id: number) => {
        const accept = async () => {
            try {
                await _deletePoster(id);
                enqueueSnackbar('Item Deleted ', { variant: 'success' });
                setRedrawTable(!redrawTable);
            } catch (err) {
                setError(err);
            }
        };
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'accept',
            acceptClassName: 'p-button-danger',
            accept
        });
    };
    useEffect(() => {
        const filtered_data = data;
        if (selectedCountry != '-1') {
            const new_data = filtered_data.filter(
                (item) => item.product_prices[0].country.id == selectedCountry
            );
            setNewData(new_data);
        } else {
            setNewData(data);
        }
    }, [selectedCountry]);
    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setselectedCountry(event.target.value);
    };
    useEffect(() => {
        if (resetFilters) {
            setSelectedOrderCountry('-1');
            setSelectedOrderCountry('-1');
            setResetFilters(false);
        }
    }, [resetFilters]);
    const selectoptions = (
        <div className="custom-select">
            <select
                id="options"
                className="select-countries custom-select"
                value={selectedCountry}
                onChange={handleSelectChange}
            >
                <option key={-1} value={-1}>
                    All Countries
                </option>
                {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                        {country.name}
                    </option>
                ))}
            </select>
        </div>
    );
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
        setResetFilters(true);
    };
    const handleSelectOrderChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedOrderCountry(event.target.value);
    };
    const selectOrderOptions = (
        <div className="custom-select">
            <select
                id="options"
                className="select-countries custom-select"
                style={{ padding: '7px' }}
                value={selectedOrderCountry}
                onChange={handleSelectOrderChange}
            >
                <option key={-1} value={-1}>
                    Select Country
                </option>
                {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                        {country.name}
                    </option>
                ))}
            </select>
        </div>
    );
    return (
        <div className="h-full" style={{ height: '100%', overflow: 'auto' }}>
            {isOpenManage ? (
                <div className="h-full">
                    <Manage
                        onClose={handleCloseManage}
                        onSave={() => setRedrawTable(!redrawTable)}
                        type={action}
                        updateId={updateId}
                    />
                </div>
            ) : (
                <div style={{ height: '100%' }}>
                    <div className="h-[120px] p-4 rounded-t-lg flex justify-between items-center px-[50px]">
                        <div>
                            <h1 className="text-xl font-bold text-[#707070]">
                                Poster
                            </h1>
                        </div>
                        <div>
                            {/* Toggle the visibility of Manage component */}
                            <button
                                className="custom-input px-4 py-2 bg-[#72BF45] w-[161px] h-38px text-white rounded-md"
                                onClick={handleOpenmanage}
                            >
                                +Add Poster
                            </button>
                        </div>
                    </div>
                    <Box
                        sx={{
                            width: '100%',
                            height: 'calc(100% - 120px)',
                            typography: 'body1'
                        }}
                    >
                        <TabContext value={tab}>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderTop: 1,
                                    borderColor: 'divider'
                                }}
                            >
                                <TabList
                                    onChange={handleTabChange}
                                    aria-label="lab API tabs example"
                                >
                                    <Tab label="List" value="1" />
                                    <Tab label="Order" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" sx={{ padding: 0 }}>
                                <Subheader
                                    multiSelectComponent={selectoptions}
                                    description="Filter Countries and Statuses"
                                />
                                <Body
                                    content={
                                        <Table
                                            rows={formattedRows}
                                            columns={formattedColumns}
                                            searchClass="search-flyer"
                                            onEdit={handleEdit}
                                            onDelete={handleDelete}
                                        />
                                    }
                                />
                            </TabPanel>
                            <TabPanel
                                value="2"
                                sx={{
                                    padding: 0,
                                    height: 'calc(100% - 50px)'
                                }}
                            >
                                <Subheader
                                    multiSelectComponent={selectOrderOptions}
                                    description="Please Select Country"
                                />
                                <Body
                                    content={
                                        <OrderPosters
                                            selectedCountry={
                                                selectedOrderCountry
                                            }
                                            // data={data}
                                        ></OrderPosters>
                                    }
                                />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
            )}
        </div>
    );
};

export default List;
