import { request, request_apikey } from 'services/ApiService';
import _config from 'config';

export interface ILoginParams {
    email: string;
    password: string;
}

interface TLoginResponse {
    data: {
        accessToken: string;
    };
}
export interface Country {
    id: number;
    name: string;
    short_name: string;
    img_path: string;
    currency: {
        name: string;
        short_name: string;
    };
    languages: {
        name: string;
        short_name: string;
        country_language: {
            created_at: string;
            updated_at: string;
            country_id: number;
            language_id: number;
        };
    }[];
}
interface TCountryResponse {
    data: Country[]; // Adjust this based on your actual API response structure
    message: string;
}
interface TCreateProductParams {
    img_path: string;
    available_countries: {
        country_id: number;
        price: number;
        product_language: {
            title: string;
            description: string;
            language_id: number;
        }[];
    }[];
}
interface TCreatePostParams {
    img_path: string;
    name: string;
    from_date: string;
    to_date: string | null;
    country_id: number | string;
    post_language: {
        title: string;
        description: string;
        language_id: number | string;
    }[];
    products: {
        product_id: number | string;
        price: number | string;
    }[];
}
interface Description {
    title: string;
    language_id: number;
}
interface PosterItem {
    img_path: string;
    sort_index: number;
}
interface TCreatePosterParams {
    name: string;
    is_active: boolean;
    country_id: number;
    poster_language: Description[];
    poster_item: PosterItem[];
}
export interface TUpdateFlyerSorting {
    posts: {
        id: number;
        index: number;
    }[];
}
export interface TUpdatePosterSorting {
    posters: {
        id: number;
        index: number;
    }[];
}
export const _login = async (params: ILoginParams) => {
    try {
        return (await request(
            _config.api_url,
            'POST',
            `/v1/users/login`,
            params
        )) as TLoginResponse;
    } catch (err) {
        throw err;
    }
};
export const _uploadimage = async (params: FormData) => {
    try {
        return await request_apikey(
            _config.api_url,
            'POST',
            `/v1/images/upload`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getCountries = async () => {
    try {
        return (await request_apikey(
            _config.api_url,
            'GET',
            `/v1/countries`,
            null,
            _config.api_secret,
            localStorage.getItem('token')
        )) as TCountryResponse;
    } catch (err) {
        throw err;
    }
};
export const _getProductById = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/products/${id}`,
            null,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _createProduct = async (params: TCreateProductParams) => {
    try {
        return await request_apikey(
            _config.api_url,
            'POST',
            `/v1/products`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getProducts = async () => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/products`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getProductsByCountry = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/products/country/${id}`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _deleteProducts = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'DELETE',
            `/v1/products/${id}`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _updateProduct = async (
    params: TCreateProductParams,
    id: number | null
) => {
    try {
        return await request_apikey(
            _config.api_url,
            'PUT',
            `/v1/products/${id}`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getPosts = async () => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/posts`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};

export const _createPost = async (params: TCreatePostParams) => {
    try {
        return await request_apikey(
            _config.api_url,
            'POST',
            `/v1/posts`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getPostById = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/posts/${id}`,
            null,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _updatePost = async (
    params: TCreatePostParams,
    id: number | null
) => {
    try {
        return await request_apikey(
            _config.api_url,
            'PUT',
            `/v1/posts/${id}`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _deletePost = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'DELETE',
            `/v1/posts/${id}`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getCityByCountryId = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/cities/country/${id}`,
            null,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getLocations = async () => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/locations`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _createLocation = async (params: any) => {
    try {
        return await request_apikey(
            _config.api_url,
            'POST',
            `/v1/locations`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};

export const _updateLocation = async (params: any, id: number | null) => {
    try {
        return await request_apikey(
            _config.api_url,
            'PUT',
            `/v1/locations/${id}`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _deleteLocation = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'DELETE',
            `/v1/locations/${id}`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getLocationById = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/locations/client/${id}`,
            null,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getProductDetails = async () => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/productdetails`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _createProductDetails = async (params: any) => {
    try {
        return await request_apikey(
            _config.api_url,
            'POST',
            `/v1/productdetails`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getLanguages = async () => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/languages`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _updateFlyerOrder = async (params: TUpdateFlyerSorting) => {
    try {
        return await request_apikey(
            _config.api_url,
            'PUT',
            `/v1/posts/sort-ranking`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};

export const _getPosters = async () => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/posters`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};

export const _createPoster = async (params: TCreatePosterParams) => {
    try {
        return await request_apikey(
            _config.api_url,
            'POST',
            `/v1/posters`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _getPosterById = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'GET',
            `/v1/posters/${id}`,
            null,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _updatePoster = async (
    params: TCreatePosterParams,
    id: number | null
) => {
    try {
        return await request_apikey(
            _config.api_url,
            'PUT',
            `/v1/posters/${id}`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _deletePoster = async (id: number) => {
    try {
        return await request_apikey(
            _config.api_url,
            'DELETE',
            `/v1/posters/${id}`,
            {},
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
export const _updatePostersOrder = async (params: TUpdatePosterSorting) => {
    try {
        return await request_apikey(
            _config.api_url,
            'PUT',
            `/v1/posters/sort-ranking`,
            params,
            _config.api_secret,
            localStorage.getItem('token')
        );
    } catch (err) {
        throw err;
    }
};
