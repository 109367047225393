import React, { useState, useEffect } from 'react';
import Subheader from '../Subheader';
// import Input from "../../Inputs/Input";
import {
    _getCountries,
    Country,
    _uploadimage,
    _updatePoster,
    _createPoster,
    _getPosterById
} from 'routes/routes';
import {
    CircularProgress,
    InputAdornment,
    Switch,
    TextField,
    alpha
} from '@mui/material';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';
import useError from 'hooks/useError';
import Header from '../Header';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { Box, Typography } from '@mui/material';
import DragAndDropArea from './DragAndDropArea';
import DraggableImageList from './DraggableImageList';
import { flyer, poster } from 'components/languages/product';
import _config from 'config';
const CustomTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 38px; // Adjust the height as needed
        border-radius: 10px;
        width: 100%;
        border: 1px solid #eeeeee;
        color: #969696;
    }
    & .MuiInputBase-root:hover,
    & .MuiInputBase-root.Mui-focused {
        border: none !important;
        border-width: 0px !important;
    }
`;
interface ProductInfo {
    title: string;
    description: string;
    language_id: number;
}

interface CountryInfo {
    country_id: number;
    price: number;
    product_language: ProductInfo[];
}
interface ManageProps {
    onClose: () => void;
    onSave: () => void;
    type: string;
    updateId: number | null;
}
export interface Image {
    id: string;
    src: string;
    loading: boolean;
}
const Manage: React.FC<ManageProps> = ({ onClose, type, onSave, updateId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const setError = useError();

    const [countries, setCountries] = useState<Country[]>([]);
    const [selectedCountry, setselectedCountry] = useState<string>('');
    const [formchanged, setFormChanged] = useState<boolean>(false);
    const [languages, setLanguages] = useState<any[]>([]);
    const [showPoster, setshowPoster] = useState(true);
    const [languageInputs, setLanguageInputs] = useState<{
        [key: string]: string;
    }>({});
    const [languageInputsError, setLanguageInputsError] = useState<{
        [key: string]: boolean;
    }>({});

    const [images, setImages] = useState<Image[]>([]);

    const handleInput2Change = (value: string, type: string) => {
        const trimmedValue = value.trimStart();
        setFormChanged(true);
        setLanguageInputs((prevState) => ({
            ...prevState,
            [`${type}`]: trimmedValue
        }));
    };
    const handleFiles = (files: File[]) => {
        const newImages = files.map((file) => ({
            id: `${file.name}-${Date.now()}`,
            src: URL.createObjectURL(file),
            file: file,
            loading: true
        }));

        setImages((prevImages) => [...prevImages, ...newImages]);

        // Upload each image asynchronously
        newImages.forEach(async (image) => {
            try {
                const uploadedFile = image.file;
                const formData = new FormData();
                formData.append('image_file', uploadedFile);
                const response = await _uploadimage(formData);
                // Update the loading status for the uploaded image
                setImages((prevImages) =>
                    prevImages.map((img) =>
                        img.id === image.id
                            ? {
                                  ...img,
                                  id: response.data.image_name,
                                  loading: false
                              }
                            : img
                    )
                );
            } catch (error) {
                console.error('Failed to upload image:', image.id);
            }
        });
    };

    const handleRemoveImage = (id: string) => {
        setImages((prev) => prev.filter((img) => img.id !== id));
    };

    const handleReorderImages = (reorderedImages: Image[]) => {
        setImages(reorderedImages);
    };

    useBeforeUnload(formchanged);
    useEffect(() => {
        if (updateId) {
            const fetchOptions = async () => {
                try {
                    const response = await _getPosterById(updateId);
                    fillinputs(response.data);
                } catch (err) {
                    setError(err);
                }
            };
            fetchOptions();
        }
    }, []);
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await _getCountries();
                setCountries(response.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchOptions();
    }, []);
    useEffect(() => {
        const selected = countries.find(
            (country) => country.id.toString() == selectedCountry
        );
        setLanguages(selected ? selected.languages : []);
    }, [selectedCountry, countries]);

    const handleClose = () => {
        setCountries([]);
        onClose();
    };
    const fillinputs = (data: {}[]) => {
        let tmpdata: any = {};
        let tmpcountry: any = 1;
        let tmpimages: any = [];
        data.forEach((poster: any) => {
            tmpdata['POSTER_ID'] = poster.name;
            tmpcountry = poster.country_id;
            poster.poster_languages.forEach((item: any) => {
                tmpdata[`description-${item.language.id}`] = item.title;
            });
            poster.poster_items.forEach((item: any) => {
                const url = new URL(
                    `${_config.s3_url}/${item.img_path.toString()}`
                );
                const image = {
                    id: item.img_path,
                    src: url,
                    loading: false
                };
                tmpimages.push(image);
            });
        });
        setselectedCountry(tmpcountry);
        setImages(tmpimages);
        setLanguageInputs(tmpdata);
    };
    const createParams = () => {
        interface Description {
            title: string;
            language_id: number;
        }
        interface PosterItem {
            img_path: string;
            sort_index: number;
        }
        interface Params {
            name: string;
            is_active: boolean;
            country_id: number;
            poster_language: Description[];
            poster_item: PosterItem[];
        }
        const poster_language: Description[] = [];
        languages.forEach((language) => {
            const poster_description: Description = {
                title:
                    languageInputs[
                        `description-${language.country_language.language_id}`
                    ] || '',
                language_id: language.country_language.language_id
            };
            poster_language.push(poster_description);
        });
        const poster_items: PosterItem[] = [];
        images.forEach((image, index) => {
            const poster_item: PosterItem = {
                img_path: image.id,
                sort_index: index + 1
            };
            poster_items.push(poster_item);
        });

        let params: Params = {
            name: languageInputs['POSTER_ID'],
            is_active: showPoster,
            country_id: parseInt(selectedCountry),
            poster_language: poster_language,
            poster_item: poster_items
        };
        console.log(params);
        return params;
    };
    const checkParams = (params: any) => {
        // const params = createParams();
        if (params.name === '' || params.name == undefined) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`POSTER_ID`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`POSTER_ID`]: false
            }));
        }
        let descriptioncheck = true;
        params.poster_language.forEach((poster: any) => {
            if (poster.title === '' || poster.title === undefined) {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`description-${poster.language_id}`]: true
                }));
                descriptioncheck = false;
            } else {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`description-${poster.language_id}`]: false
                }));
            }
        });
        if (params.name === '' || params.name == undefined) {
            return {
                validation: false,
                msg: 'POSTER ID input is Required'
            };
        }
        if (!descriptioncheck) {
            return {
                validation: false,
                msg: 'Description field is Required'
            };
        }

        return {
            validation: true,
            msg: ''
        };
    };
    const handleSubmit = async () => {
        const params = createParams();
        const validation = checkParams(params);
        if (type == 'Edit') {
            if (validation.validation) {
                try {
                    await _updatePoster(params, updateId);
                    enqueueSnackbar('Product Updated ', { variant: 'success' });
                    handleClose();
                } catch (err) {
                    setError(err);
                }
            } else {
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        } else {
            if (validation.validation) {
                try {
                    await _createPoster(params);
                    enqueueSnackbar('Product Created ', { variant: 'success' });
                    handleClose();
                } catch (err) {
                    setError(err);
                }
            } else {
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        }
        onSave(); // Call the onClose callback to close the component
    };

    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setFormChanged(true);
        setselectedCountry(event.target.value);
        const selected = countries.find(
            (country) => country.id.toString() == event.target.value
        );
        setLanguages(selected ? selected.languages : []);
    };
    // type == 'Edit';
    const selectoptions = (
        <div className="custom-select">
            <select
                id="options"
                className="select-countries custom-select"
                value={selectedCountry}
                onChange={handleSelectChange}
                disabled={type == 'Edit' ? true : false}
            >
                <option key={-1}>Select Country</option>
                {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                        {country.name}
                    </option>
                ))}
            </select>
        </div>
    );
    const CustomSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#72BF45',
            '&:hover': {
                backgroundColor: alpha('#72BF45', 0.2)
            }
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#72BF45'
        }
    }));
    return (
        <div className="h-full">
            <Header
                type={type + ' Product'}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
            />
            <Subheader
                multiSelectComponent={selectoptions}
                description="Select Countries you want your product to be displayed"
            />
            {selectedCountry !== '' ? (
                <div
                    className="flex manage-body pb-[50px]"
                    style={{ height: 'calc(100% - 200px);' }}
                >
                    <div className="body-conten product flex flex-col px-[50px] w-full">
                        <div className="flex h-full w-full">
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                                gap={2}
                                p={2}
                            >
                                <Box
                                    display="flex"
                                    gap={2}
                                    flexDirection="column"
                                >
                                    <Box
                                        flex={1}
                                        className="header-text py-4 text-[#969696] font-bold text-lg "
                                    >
                                        <span>Poster information</span>
                                    </Box>
                                    <Box display="flex" gap={4}>
                                        <div className="product-name flex flex-col gap-2 flex-1">
                                            <label>Poster Id</label>
                                            <CustomTextField
                                                type="text"
                                                error={
                                                    languageInputsError[
                                                        `POSTER_ID`
                                                    ]
                                                }
                                                required
                                                placeholder="Poster Id"
                                                value={
                                                    languageInputs[
                                                        `POSTER_ID`
                                                    ] || ''
                                                }
                                                onChange={(e) =>
                                                    handleInput2Change(
                                                        e.target.value,
                                                        'POSTER_ID'
                                                    )
                                                }
                                                style={{
                                                    background: '#F9FDF7'
                                                }}
                                            />
                                        </div>
                                        <div className="product-name flex flex-col gap-2 flex-1">
                                            <label>Poster title</label>
                                            {languages.map((language) => (
                                                <>
                                                    <CustomTextField
                                                        type="text"
                                                        required
                                                        placeholder={
                                                            poster.name[
                                                                language.short_name as keyof typeof poster.name
                                                            ]
                                                        }
                                                        error={
                                                            languageInputsError[
                                                                `description-${language.country_language.language_id}`
                                                            ]
                                                        }
                                                        value={
                                                            languageInputs[
                                                                `description-${language.country_language.language_id}`
                                                            ] || ''
                                                        }
                                                        onChange={(e) =>
                                                            handleInput2Change(
                                                                e.target.value,
                                                                `description-${language.country_language.language_id}`
                                                            )
                                                        }
                                                        style={{
                                                            background:
                                                                '#F9FDF7'
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <div
                                                                        className={`flag-${language.short_name}`}
                                                                    ></div>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </>
                                            ))}
                                        </div>
                                        <div className="product-name flex flex-col gap-2 flex-1">
                                            <label>Upload Images</label>
                                            <DragAndDropArea
                                                onFilesDropped={handleFiles}
                                            />
                                        </div>
                                    </Box>
                                </Box>

                                {/* Right: Draggable List */}
                                <Box
                                    display="flex"
                                    gap={1}
                                    flexDirection="column"
                                >
                                    <div className="header-text py-4 text-[#969696] font-bold text-lg ">
                                        <span>Uploaded Images</span>
                                    </div>
                                    <DraggableImageList
                                        images={images}
                                        onRemove={handleRemoveImage}
                                        onReorder={handleReorderImages}
                                    />
                                </Box>
                            </Box>
                        </div>
                    </div>
                </div>
            ) : type !== 'Edit' ? (
                <div className="flex manage-body f24 font-bold text-[#707070] justify-center items-center">
                    Please select country to countinue!
                </div>
            ) : (
                <div className="flex manage-body f24 font-bold text-[#707070] justify-center items-center">
                    <CircularProgress></CircularProgress>
                </div>
            )}
        </div>
    );
};

export default Manage;
