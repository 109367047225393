import React from 'react';
import {
    DataGrid,
    GridColDef,
    GridRowsProp,
    GridToolbarQuickFilter,
    GridPagination,
    useGridApiContext,
    useGridSelector,
    gridPageCountSelector
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { TablePaginationProps } from '@mui/material/TablePagination';

interface CustomDataGridProps {
    columns: GridColDef[];
    rows: GridRowsProp;
    onEdit?: (id: number) => void;
    onDelete?: (id: number) => void;
    onRowSelectionModelChange?: (selection: any) => void;
    searchClass?: string;
    checkboxSelection?: boolean;
}

function Pagination({
    page,
    onPageChange,
    className
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <MuiPagination
            // classes={{ ul:  }}
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            sx={{
                '& .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root': {
                    backgroundColor: '#72bf4'
                }
            }}
            onChange={(event, newPage) => {
                onPageChange(event as any, newPage - 1);
            }}
        />
    );
}
function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}
const CustomDataGrid: React.FC<CustomDataGridProps> = ({
    columns,
    rows,
    onEdit,
    onDelete,
    searchClass = 'filter-main-tables',
    onRowSelectionModelChange,
    checkboxSelection = false
}) => {
    function QuickSearchToolbar() {
        return (
            <div className={searchClass}>
                <GridToolbarQuickFilter />
            </div>
        );
    }
    return (
        <div
            style={{ height: '100%', width: '100%' }}
            className="table-container"
        >
            <DataGrid
                pagination
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20
                        }
                    }
                }}
                rowHeight={55}
                columnHeaderHeight={75}
                checkboxSelection={checkboxSelection}
                autoHeight={false}
                pageSizeOptions={[5]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                density="comfortable"
                slots={{
                    toolbar: QuickSearchToolbar,
                    pagination: CustomPagination
                }}
                // }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        color: '#ADADAD',
                        fontSize: '17px',
                        background: '#e7e7e7',
                        minHeight: '70px'
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        color: '#969696',
                        fontSize: '14px',
                        fontWeight: '600'
                    }
                }}
                onCellClick={(params) => {
                    if (params.field === 'edit') {
                        if (onEdit) {
                            console.log('here');
                            onEdit(params.row.productId);
                        }
                    } else if (params.field === 'delete') {
                        if (onDelete) {
                            onDelete(params.row.productId);
                        }
                    }
                }}
            />
        </div>
    );
};

export default CustomDataGrid;
