import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, IconButton, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface Image {
    id: string;
    src: string;
    loading: boolean;
}

interface DraggableImageListProps {
    images: Image[];
    onRemove: (id: string) => void;
    onReorder: (reorderedImages: Image[]) => void;
}

const DraggableImageList: React.FC<DraggableImageListProps> = ({
    images,
    onRemove,
    onReorder
}) => {
    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedImages = Array.from(images);
        const [moved] = reorderedImages.splice(result.source.index, 1);
        reorderedImages.splice(result.destination.index, 0, moved);
        onReorder(reorderedImages);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="images" direction="horizontal">
                {(provided) => (
                    <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        display="flex"
                        flexDirection="row"
                        overflow="auto"
                        maxWidth="100%"
                        gap={2}
                        p={2}
                        sx={{
                            whiteSpace: 'nowrap', // Prevent wrapping
                            scrollbarWidth: 'thin' // Hide scrollbar for better aesthetics
                        }}
                    >
                        {images.map((image, index) => (
                            <Draggable
                                key={image.id}
                                draggableId={image.id}
                                index={index}
                            >
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        border="1px solid #ccc"
                                        borderRadius={2}
                                        bgcolor="#fff"
                                        // width="200px"
                                        // height="250px"
                                        position="relative"
                                    >
                                        {image.loading ? (
                                            <CircularProgress
                                                size={40}
                                                sx={{ marginTop: 'auto' }}
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    backgroundImage: `url(${image.src})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition:
                                                        'center',
                                                    borderRadius: 4,
                                                    width: '170px',
                                                    height: '238px'
                                                }}
                                            />
                                        )}
                                        <IconButton
                                            color="error"
                                            onClick={() => onRemove(image.id)}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0.7)',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'rgba(255, 255, 255, 1)'
                                                }
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableImageList;
