import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';

interface DragAndDropAreaProps {
    onFilesDropped: (files: File[]) => void;
}

const DragAndDropArea: React.FC<DragAndDropAreaProps> = ({
    onFilesDropped
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        multiple: true,
        onDrop: (acceptedFiles) => onFilesDropped(acceptedFiles)
    });

    return (
        <Box
            {...getRootProps()}
            p={4}
            textAlign="center"
            sx={{ cursor: 'pointer' }}
            className="dragAndDropZone h-[86px] w-[450px] rounded border-4 border-dashed border-gray-400 border-opacity-50 bg-[#F9FDF7] flex flex-col justify-center items-center cursor-pointer relative"
            onMouseEnter={() => {
                // Add onMouseEnter event to change color on hover
                const browseSpan = document.getElementById('browse');
                if (browseSpan) {
                    browseSpan.style.color = '#71BF45'; // Change color to green
                }
            }}
            onMouseLeave={() => {
                // Add onMouseLeave event to revert color when not hovered
                const browseSpan = document.getElementById('browse');
                if (browseSpan) {
                    browseSpan.style.color = '#969696'; // Revert color to default
                }
            }}
        >
            <input {...getInputProps()} />
            <div className="text-xl text-[#969696] font-bold">
                Drag & drop files or <span id="browse">Browse</span>
            </div>
            <div className="text-md text-[#969696]">
                Supported formates: JPEG, PNG, PDF not larger than 5MB
            </div>
        </Box>
    );
};

export default DragAndDropArea;
